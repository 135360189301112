// W3media frontend entrypoint "lazy-load"
require('/builds/project/crowntent/node_modules/@w3media/framework-styles/assets/lazy-load.js');

// W3media frontend entrypoint "aos"
import('/builds/project/crowntent/node_modules/@w3media/framework-styles/assets/aos.js');

// W3media frontend entrypoint "current-device"
require('/builds/project/crowntent/vendor/w3media/framework/src/Bundle/Theme/Resources/public/js/current-device.js');

// W3media frontend entrypoint "bootstrap-accordion"
import('/builds/project/crowntent/vendor/w3media/framework/src/Bundle/Frontend/Resources/public/js/bootstrap-accordion.js');

// W3media frontend entrypoint "icons-fontawesome5"
import('/builds/project/crowntent/node_modules/@w3media/framework-styles/assets/font-awesome-5.js');

// W3media frontend entrypoint "form"
import('/builds/project/crowntent/vendor/w3media/framework/src/Bundle/Form/Resources/assets/form.js');
